body.tarteaucitron-modal-open {
    overflow: hidden;
    @include mq(768px,null){
        overflow-y: auto;
    }
}
#tarteaucitronRoot * {
    box-sizing: border-box;
}
#tarteaucitronRoot {
    font-family: sans-serif;
    color: #333;
    font-size: 12px;
    line-height: normal;
    vertical-align: initial;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    ol,
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    blockquote,
    q {
        quotes: none;
        &:before,
        &:after {
            content: '';
            content: none;
        }
    }
    button {
        border: none;
        background-color: transparent;
        color: #333;
        &:focus-visible,
        &:focus {
            outline: none;
        }
    }
    a.tarteaucitronSelfLink {
        display: none;
    }
    .tac-wrap {}
    .tac-left {
        padding: 10px;
        @include mq(768px,null){
            padding: 20px;
        }
        .tac-title {
            font-size: 18px;
            font-weight: 700;
            margin: 0 0 8px 0;
        }
        img {
            margin: 0 0 10px 0;
            width: 100px;
        }
    }
    .tac-right {
        display: flex;
        justify-content: center;
        border-top: 1px solid gray;
        > * {
            flex: 1 1 0;
        }
        button {
            margin: 0;
            padding: 15px 10px;
            color: #333;
            cursor: pointer;
            &:not(:first-child){
                border-left: 1px solid gray;
            }
            @include mq(null,479px){
                &#tarteaucitronPrivacyUrl {
                    display: none;
                }
            }
        }
        .tarteaucitronCross:before,
        .tarteaucitronCheck:before,
        .tarteaucitronPlus:before {
            color: black;
        }
    }
    .tac-trigger {
        position: fixed;
        bottom: 20px;
        left: 20px;
        z-index: 1000;
        &--link {
            width: 44px;
            height: 44px;
            border-radius: 74% 26% 37% 63% / 61% 26% 74% 39%;
            display: flex;
            justify-content: center;
            background: $color-1;
            padding: 0;
            border: 1px solid white;
            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                @include font-size-lh(20,20);
                color: $white;
            }
        }
    }
    .tarteaucitronWrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @include mq(768px,null){
            align-items: center;
        }
    }
    .tarteaucitronCross:before {
        content: '\2717';
        display: inline-block;
        color: white;
    }
    .tarteaucitronCheck:before {
        content: '\2713';
        display: inline-block;
        color: white;
    }
    .tarteaucitron-display-block {
        display: block;
    }
    .tarteaucitron-display-none {
        display: none;
    }
    #tarteaucitronBack {
        display: none !important;
        pointer-events: none;
        height: 100%;
        left: 0;
        background-color: rgba(255,255,255,0);
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 2147483646;
    }
    #tarteaucitronAlertBig {
        position: fixed;
        background-color: $white;
        bottom: 20px;
        left: 20px;
        border: 1px solid gray;
        border-radius: 10px;
        z-index: 2147483645;
        box-shadow: 0 0 5px rgba(0,0,0,.3);
        width: calc(100% - 40px);
        max-width: 500px;
        &:focus-visible {
            outline: none;
        }
    }
    #tarteaucitron {
        position: fixed;
        display: none;
        top: inherit !important;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto 20px 20px;
        border: 1px solid gray;
        border-radius: 10px;
        background-color: $white;
        box-shadow: 0 0 5px rgba(0,0,0,.3);
        z-index: 2147483647;
        overflow: hidden;
        width: calc(100% - 40px);
        max-width: 800px;
        max-height: 80%;
        padding: 0;
        a {
            color: #333;
        }
        #tarteaucitronClosePanel {
            //position: absolute;
            //top: 0;
            //right: 20px;

            //position: relative;
            //left: 100%;
            //transform: translateX(-100%);

            color: #333;
            cursor: pointer;
            font-size: 12px;
            padding: 5px 0 5px 15px;
            text-decoration: underline;
        }
        #tarteaucitronServices {
            #tarteaucitronMainLineOffset {}
            #tarteaucitronInfo {
                display: block;
                position: relative;
                padding: 15px 0;
                font-size: 12px;
                #tarteaucitronPrivacyUrlDialog {
                    padding: 0;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            #tarteaucitronServices_mandatory {
                .tarteaucitronAllow {
                    opacity: 1;
                }
            }
            .tarteaucitronH1 {
                font-size: 18px;
                font-weight: 700;
                color: black;
                display: block;
            }
            .tarteaucitronH2 {
                font-size: 12px;
                font-weight: 700;
                margin: 12px 0;
            }
            .tarteaucitronH3 {
                display: block;
                font-size: 12px;
                font-weight: 700;
                margin: 0 0 5px 0;
            }
            .tarteaucitronBorder {}
            .tarteaucitronLine {
                overflow: hidden;
                padding: 15px;
                .tarteaucitronAsk {
                    width: 100%;
                    margin: 8px 0 0 0;
                    display: inline-block;
                    text-align: center;
                    @include mq(768px,null){
                        width: auto;
                        margin: 7px 15px;
                        text-align: right;
                        float: right;
                    }
                }
                .tarteaucitronAllow,
                .tarteaucitronDeny {
                    display: block;
                    width: 100%;
                    margin: 0 0 8px 0;
                    background: gray;
                    border-radius: 4px;
                    color: #fff;
                    cursor: pointer;
                    padding: 5px 10px;
                    text-align: center;
                    text-decoration: none;
                    border: 0;
                    opacity: .6;
                    @include mq(768px,null){
                        display: inline-block;
                        width: auto;
                        margin: 0;
                    }
                }
                &.tarteaucitronIsDenied {
                    .tarteaucitronDeny {
                        background-color: #9C1A1A;
                        opacity: 1;
                    }
                }
                &.tarteaucitronIsAllowed {
                    .tarteaucitronAllow {
                        background-color: #1B870B;
                        opacity: 1;
                    }
                }
                .tarteaucitronName {
                    float: left;
                    width: 100%;
                    text-align: left;
                    @include mq(768px,null){
                        width: 50%;
                    }
                }
            }
            .tarteaucitronTitle {
                padding: 0;
                border-top: 1px solid gray;
                border-bottom: 1px solid gray;
                button {
                    display: block;
                    text-align: left;
                    padding: 5px 15px;
                    border: none;
                    background: gray;
                    color: white;
                }
                .tarteaucitronPlus:before {
                    content: '\271b';
                    display: inline-block;
                    color: white;
                }
            }
            .tarteaucitronDetails {
                position: absolute;
                padding: 5px 15px;
                border: 1px solid gray;
                background-color: gray;
                color: white;
                max-width: 270px;
                display: none;
                font-size: 12px;
            }
            .tarteaucitronHidden {
                display: none;
                position: relative;
            }
        }
    }
}
.tac_activate {
    background: #333;
    color: #fff;
    display: table;
    font-size: 12px;
    height: 100%;
    line-height: initial;
    margin: auto;
    text-align: center;
    width: 100%;
    .tac_float {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        strong {
            color: #fff;
        }
        .tarteaucitronAllow {
            background-color: #1B870B;
            display: inline-block;
            border: none;
            padding: 5px;
            border-radius: 5px;
            color: white;
        }
    }
}