*,*:before,*:after {
    box-sizing: border-box;
}

@import "components/codyframe";

@import "common";

@import "components/rgpd";
@import "layout/fo";
