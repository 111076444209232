.profile {
    .container {
        @include container(600px);
    }
    &--login,
    &--reset {
        background-color: $color-2;
        padding: 20px 40px;
    }
    &--reset {
        p {
            a {
                color: $color-1;
            }
        }
    }
    &--title {
        text-align: center;
    }
    &--form {
        form {
            > div {
                margin-bottom: 10px;
            }
        }
        label {}
        input {
            width: 100%;
        }
        button {
            color: $white;
            background-color: $black;
            border: 1px solid $black;
            padding: 10px 15px;
            transition: all 0.3s;
            &:hover {
                color: $black;
                background-color: transparent;
            }
        }
    }
    &--actions {
        display: flex;
        align-items: center;
        margin: 0 -10px;
    }
    &--action {
        margin: 0 10px;
        a {
            text-decoration: none;
        }
        p {
            color: $black;
            padding: 5px 10px;
            border: 1px solid $black;
            i {
                margin-right: 5px;
            }
        }
    }
}