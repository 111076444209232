// In footer for JS mobile detection (based on px viewport wide)
.ismobile {
    @include visuallyhidden;
    @include navDesktop {
        display: none;
    }
}
html {
    height: 100%;
}
html,body {
    scroll-behavior: smooth;
}
body {
    @include poppins();
    font-size: 1rem;
    line-height: 1.4;
    color: $color-1;
    overflow-x: hidden;
    min-height: 100%;
    background-color: $body-bg;
    margin: 0;
}
img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    background-size: cover;
}
.container {
    @include container();
}
.header {
    background-color: $black;
    @include navDesktop {
        position: fixed;
        top: 100px;
        left: 0;
        width: 200px;
        height: calc(100% - 100px);
    }
    &--navigation {
        @include navMobile {
            opacity: 0;
            pointer-events: none;
            position: fixed;
            top: 100px;
            left: 0;
            width: 100%;
            //height: 100vh;
            max-height: calc(100vh - 120px);
            z-index: 1000;
            background-color: $black;
            padding: 10px;
            overflow: auto;
            box-shadow: 0 3px 6px rgba($color-1, 0.75);
            //transition: all .3s;
            .menu-open & {
                opacity: 1;
                pointer-events: auto;
            }
        }
        ul {
            list-style: none;
            padding: 0 20px;
            li {
                a {
                    color: $white;
                    text-decoration: none;
                }
                i {
                    text-align: center;
                    width: 30px;
                }
            }
        }
    }
    &--top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: $black;
        padding: 15px;
        width: 100%;
        min-height: 100px;
        position: sticky;
        top: 0;
        &--logo {
            flex: 0 0 25%;
            @include navMobile {
                flex-basis: calc(100% / 3);
            }
            img {
                max-width: 150px;
            }
        }
        &--name {
            flex: 0 0 50%;
            @include navMobile {
                flex-basis: calc(100% / 3);
            }
            @include mq(null, 767px) {
                flex-basis: calc(100% / 3 * 2);
                display: none;
            }
            p {
                @include font-size-lh(20, 25);
                color: $white;
                text-align: center;
            }
        }
        &--profile {
            flex: 0 0 25%;
            @include navMobile {
                display: none;
            }
            p {
                color: $white;
                text-align: right;
            }
        }
    }
}

.nav {
    &--trigger {
        position: fixed;
        top: 15px;
        right: 15px;
        padding: 0;
        margin: 0;
        width: 60px;
        height: 70px;
        background: none;
        border: none;
        color: $white;
        @include font-size-lh(30, 30);
        @include navDesktop {
            display: none;
        }
        > span {
            vertical-align: middle;
        }
        &--open {

        }
        &--close {
            display: none;
        }
        &--prev {
            display: none;
        }
        &--text {
            display: none;
        }
        .menu-open & {
            .nav--trigger--open {
                display: none;
            }
            .nav--trigger--close {
                display: block;
            }
            .nav--trigger--prev {}
        }
        .sub-menu-open & {
            .nav--trigger--open {
                display: none;
            }
            .nav--trigger--close {
                display: none;
            }
            .nav--trigger--prev {
                display: block;
            }
        }
    }
}

img,
svg {
    &.svg {
        opacity: 0;
        transition: all 1s;
        &.replaced-svg {
            opacity: 1;
        }
    }
}

.content {
    width: 100%;
    @include navDesktop {
        body.connect & {
            padding-left: 200px;
        }
    }
    &--inner {
        padding: 15px;
    }
}