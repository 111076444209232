$black: #000;
$white: #fff;

$container: 1024px;
$bp-menu: 1024px;

$body-bg: $white;

$error: #CE1431;
$success: #2EA836;

$color-1: #111111;
$color-2: #F2F2F2;

$primary: $color-1;

$imgPath           : "../img/";

//$blue-dark: $color-1;
//$blue: $color-2;
//$blue-light: #93CBDF;
//$blue-green: #2BB497;

$colors: (
    "black": $black,
    "white": $white,
    "primary": $primary,
    "color-1": $color-1,
    "color-2": $color-2,
    "error": $error,
    "success": $success,
);
:root {
    @each $color in $colors {
        --#{nth($color,1)}: #{nth($color,2)};
    }
    --youtube-color: #FF0000;
    --facebook-color: #1877F2;
    --instagram-color: #ED7158;
    --twitter-color: #1DA1F2;
    --text-color: #{$primary};
    --main-color: #{$primary};
}
@each $color in $colors {
    .bg-#{nth($color,1)} {
        background-color: #{nth($color,2)};
    }
}
//$colors: ($black, $white, $color-1, $color-2, $color-3, $color-4, $color-5, $color-6, $color-7, $color-8, $color-9, $color-10);
//@for $i from 1 to length($colors) + 1 {
//    .bg-#{$colors} {
//        background-color: #{nth($colors, $i)};
//    }
//}
//:root {
//    @for $i from 1 to length($colors) + 1 {
//        --color-#{$i}: #{nth($colors, $i)};
//        //--color-light#{$i}: #{lighten(nth($colors, $i),10%)};
//        //--color-dark#{$i}: #{darken(nth($colors, $i),10%)};
//    }
//}