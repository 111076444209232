.flashbag-messages {
    body.connect & {
        padding-left: 200px;
    }
    .container {
        @include container(600px);
    }
    .card {
        box-shadow: 0 2px 2px 0 rgba($black, 0.15);
        &.success {
            background-color: $success;
        }
        &.error {
            background-color: $error;
        }
        &-content {
            padding: 20px;
        }
    }
}