$sans-serif: Arial, Tahoma, sans-serif;
$serif: "Times New Roman", Times, serif;
$arial: $sans-serif;
$times: $serif;
@mixin arial($fontWeight: 400) {
    font-family: $arial;
    font-weight: $fontWeight;
}
@mixin times($fontWeight: 400) {
    font-family: $times;
    font-weight: $fontWeight;
}
$Poppins: 'Poppins', $sans-serif;
@mixin poppins($fontWeight: 400, $important: false) {
    @if($important){
        font-family: $Poppins !important;
    } @else {
        font-family: $Poppins;
    }
    font-weight: $fontWeight;
}