:root {
    --nb-month: 2;
}
.documents {
    &--list {
        &--title {
            margin-top: 50px;
        }
        &--inner {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        &--documents {
            width: calc(100% / var(--nb-month) - 15px);
            margin-bottom: 15px;
            .dashboard & {
                width: calc(100% / 2 - 15px);
            }
            @include mq(1280px, 1440px) {
                width: calc(100% / 3 - 15px);
            }
            @include mq(768px, 1279px) {
                width: calc(100% / 2 - 15px);
            }
            @include mq(null, 767px) {
                width: 100%;
            }
            &--title {
                color: $white;
                background-color: rgba($black, 0.75);
                padding: 5px 15px;
            }
            &--items {
                border: 1px solid $black;
            }
            &--item {
                padding: 5px 15px;
                transition: all 0.3s;
                &:nth-child(even) {
                    background-color: rgba($black, 0.15);
                }
                &:hover {
                    background-color: rgba($black, 0.25);
                }
                &--link {
                    color: $color-1;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                h4 {
                    margin-right: 30px;
                }
                p {
                    text-align: center;
                    flex: 0 0 25%;
                }
            }
            &--empty {
                padding: 5px 15px;
            }
        }
    }
}