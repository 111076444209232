@mixin font-size-lh($sizeValue: 16, $lineHeight:$sizeValue * 1.4) {
    //font-size: $sizeValue + px;
    //line-height: $lineHeight + px;
    font-size: ($sizeValue / 16) + rem;
    line-height: ($lineHeight / 16) + rem;
}
@mixin font-size($sizeValue: 16) {
    //font-size: $sizeValue + px;
    font-size: ($sizeValue / 16) + rem;
}
@mixin dotdotdot($sizeValue: 16, $lineHeight:$sizeValue * 1.4, $nbRow:2) {
    position:relative;
    overflow:hidden;
    //font-size: $sizeValue + px;
    //line-height: $lineHeight + px;
    //height: $lineHeight * $nbRow + px;
    font-size: ($sizeValue / 16) + rem;
    line-height: ($lineHeight / 16) + rem;
    height: ($lineHeight / 16) * $nbRow + rem;
    padding: 0;
}
@mixin mq($min, $max) {
    @if $min == null {
        @media (max-width: #{$max}) {
            @content;
        }
    } @else if $max == null {
        @media (min-width: #{$min}) {
            @content;
        }
    } @else {
        @media (min-width: #{$min}) and (max-width: #{$max}) {
            @content;
        }
    }
}
@mixin cqn($name) {
    container-type: inline-size;
    container-name: $name;
}
@mixin cq($cqn, $min, $max) {
    @if $min == null {
        @container #{$cqn} (max-width: #{$max}) {@content;}
    } @else if $max == null {
        @container #{$cqn} (min-width: #{$min}) {@content;}
    } @else {
        @container #{$cqn} (min-width: #{$min}) and (max-width: #{$max}) {@content;}
    }
}
// Modernizr
@mixin touch(){
    .touchevents & {
        @content;
    }
}
@mixin no-touch(){
    .no-touchevents & {
        @content;
    }
}

@function get-vw($size: 1, $vw: 1920) {
    $calc: ($size / $vw * 100) * 1vw;
    @return $calc;
}
@mixin container($width: $container, $padding: 0){
    width: $width;
    max-width: 100%;
    padding: $padding;
    margin-left: auto;
    margin-right: auto;
}
@mixin font-size-vw($sizeValue: 1.6, $lineHeight: $sizeValue * 1.4, $vw: 1920, $important: false) {
    @if ($lineHeight == '')  {
        $lineHeight: $sizeValue * 1.4
    }
    $sizeValue: get-vw($sizeValue, $vw);
    $lineHeight: get-vw($lineHeight, $vw);
    @if $important {
        font-size: $sizeValue !important;
        line-height: $lineHeight !important;;
    } @else {
        font-size: $sizeValue;
        line-height: $lineHeight;
    }
}
@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
@mixin abs_cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
@mixin object-fit($fit: cover, $position: center) {
    @include abs_cover;
    object-fit: $fit;
    object-position: $position;
}
@mixin letter-spacing($spacing) {
    letter-spacing: ($spacing / 1000) * 1em;
}
@mixin navMobile(){
    @include mq(null,$bp-menu - 1px){
        @content;
    }
}
@mixin navDesktop(){
    @include mq($bp-menu,null){
        @content;
    }
}


@mixin darkMode(){
    @media (prefers-color-scheme: dark) {
        @content;
    }
}
@mixin lightMode(){
    @media (prefers-color-scheme: light) {
        @content;
    }
}