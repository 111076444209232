.cta {
    margin-top: 20px;
    &--link {
        color: $color-1;
        text-decoration: none;
        display: inline-flex;
        transition: all 0.3s;
    }
    &--title {
        color: $color-1;
        border: 1px solid $black;
        border-radius: var(--btn-radius, .25em);
        padding: 5px 10px;
        margin: 0;
        transition: all 0.3s;
        .cta--link:hover & {
            color: $white;
            background-color: $black;
        }
    }
}